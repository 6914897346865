import React from "react"
import {Helmet} from "react-helmet"
import Layout from "../../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
//import Row from 'react-bootstrap/Row'
//import Col from 'react-bootstrap/Col'
import "../../styles/styles.scss"
import attractionStyles from "./atrakcje.module.scss"
import Logo from "../../components/logo"
import "intersection-observer"
import leftCorner from "../../images/decorative-corner-left.svg"
import basketImg from "../../images/atrakcje/basket.jpg"
import bigBasketImg from "../../images/atrakcje/The_worlds_biggest_wicker_basket-Nowy_Tomyśl.jpg"
import museumImg from "../../images/atrakcje/Muzeum_Wikliniarstwa_i_Chmielarstwa_Nowy_Tomysl.jpg"

const AttractionPage = () => (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pl" />
        <title>Atrakcje | Pensjonat U Wierzby Pod Lasem</title>
        <meta name="description" content="Zapraszamy do zapoznania się z atrakcjami okolicy Pensjonatu U Wierzby Pod Lasem."/>
        <link rel="canonical" href="https://www.uwierzby.pl/atrakcje/"/>
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link rel="preload" href="https://fonts.googleapis.com/css2?family=Charm&family=Cormorant+SC:wght@300&family=Quicksand&display=swap" as="style"></link>
      </Helmet>
      <Layout>
        <section className={`JumbotronSection ${attractionStyles.JumbotronSection___attraction}`}>
          <Jumbotron fluid className={`Jumbotron`}>
            <div className={`Jumbotron_overlay`}></div>
            <div className={`Jumbotron_logo`}>
              <Logo/>
            </div>
            <Container fluid className={`Jumbotron_container`}>
              <h1 className="animate__animated animate__slideInLeft">atrakcje</h1>
              <h3 className={`SectionSubTitleH3 light animate__animated animate__slideInRight`}>w okolicy</h3>
            </Container>
          </Jumbotron>
        </section>
        <section className={`BreadcrumbSection`}>
          <Breadcrumb className={`Breadcrumb`}>
              <Breadcrumb.Item className={`Breadcrumb_item`} href="../">Strona Główna</Breadcrumb.Item>
              <Breadcrumb.Item className={`Breadcrumb_item`} active>Atrakcje</Breadcrumb.Item>
          </Breadcrumb>
        </section>
        <section className={`MainSection`}>
          <Container fluid className={`MainSection_wrapper`}>
            <img className={`MainSection_corner___left`} src={leftCorner} alt="top left decorative corner"></img>
            <div className={`MainSection_textWrapper`}>
              <div className={`SectionTitle_wrapper`}>
                <div className={`SectionTitle_bigLetter`}><p>A</p></div>
                <h1 className={`SectionTitle`}>atrakcje</h1>
                <h3 className={`SectionSubTitleH3`}>miasto wikliniarzy</h3>
              </div>
              <div className={`MainSection_main`}>
                <div className={`MainSection_mainText limited`}>
                  <p>Nasza agroturystyka położona jest w zacisznym wiejskim otoczeniu, dzięki czemu mogą Państwo cieszyć się niezakłóconym wypoczynkiem, ale jednocześnie znajduje się <span>niecałe 10 minut</span> drogi od barwnego miasta Nowy Tomyśl z licznymi atrakcjami turystycznymi.</p>

                  <p>Należą do nich:</p>
                  <div className={attractionStyles.AttractionsList_wrapper}>
                    <ul className={attractionStyles.AttractionsList}>
                      <li>Muzeum Wikliniarstwa i Chmielarstwa</li>
                      <li>Ogród zoologiczny i Park Miejski</li>
                      <li>Osadnictwo olęderskie</li>
                      <li>Zabytki gminne</li>
                      <li>Wikli (Plenery Wikliniarskie, Pikniki z Wikliną, Spacer wiklinowym deptakiem)</li>
                      <li>Nowotomyskie murale</li>
                    </ul>
                  </div>
              </div>
              <div className={`MainSection_mainImg`}>
                <img className={attractionStyles.BasketImg} src={basketImg} alt="wiklinowe kosze"></img>
              </div>
              <div className={`MainSection_mainText limited`}>
                  <p>Na ternach Nowego Tomyśla można odnaleźć ślady osadnictwa sprzed <span>11 tysięcy lat</span>. Miasto to ma bogatą tradycję uprawy wikliny i chmielu. Naturalne warunki terenowe sprawiły, że od zawsze rosła tutaj w stanie dzikim <span>wierzba, z której to powstają wyroby wikliniarskie</span>. Początkowo do produkcji koszy wykorzystywano dziko rosnącą wierzbę koszykarską, ale z czasem rozpoczęto planową jej uprawę. Dobór odpowiednich odmian wikliny doprowadził do uzyskania wartościowego materiału, w oparciu o który wyroby wikliniarskie z terenu nowotomyskiego zdobyły sobie uznanie w kraju i za granicą.</p>
                  <div className={`MainSection_mainImg`}><img src={bigBasketImg} className={attractionStyles.BasketImg} alt="osadnitwo olęderskie Nowy Tomyśl"/>
                  </div>
                  <p>W 2000 roku powstał w Nowym Tomyślu <span>największy kosz wiklinowy świata</span>. Do jego produkcji wykorzystano 8 ton wikliny i uzyskał zapis w <span>księdze Rekordów Guinnessa</span>. Od tego czasu dzięki współpracy plecionkarzy i wikliniarzy oraz zaangażowaniu wielu mieszkańców, firm i instytucji powstają na terenie miasta wiklinowe formy zdobiące miejską przestrzeń, czego nie spotkacie Państwo w żadnym innymi mieście.</p>

                  <p>Przez centrum miasta przebiega wyjątkowy szlak pieszy - <span>"Spacer wiklinowym deptakiem"</span>.</p>

                  <p>Szlak zaczyna się przy największym wiklinowym koszu świata na pl. Niepodległości. Dalej trasa biegnie przez ulicę Mickiewicza, którą zdobią różnorodne wiklinowe formy: oploty elewacji budynków, wiklinowe kwietniki, parasole, postać zwana „Tomysem” i urokliwy pasażem. Szlak kończy się przy muszli koncertowej – całej oplecionej wikliną.</p>
                  <div className={`MainSection_mainImg`}><img src={museumImg} className={attractionStyles.BasketImg} alt="Muzemu Wikliniarstwa i Chmielarstwa"/>
                  </div>
                  <p><span>Muzeum Wikliniarstwa i Chmielarstwa</span> organizuje lekcje muzealne, warsztaty plecionkarskie, pokazy – wyplatania oraz z zakresu przetwórstwa wikliny. Prezentowane są, związane z tą tematyką narzędzia i maszyny oraz ogromne bogactwo wyrobów plecionkarskich, zarówno o charakterze użytkowym, jak i artystycznym. W czerwcu Muzeum organizuje <span>Ogólnopolskie Plenery Wikliniarskie</span>, w sierpniu zaś podczas <span>Jarmarku Chmielo-Wikliniarskiego</span>, na terenie Muzeum, odbywa się <span>Ogólnopolski Konkurs Plecionkarski</span>.</p>

                  <p>Liczne atrakcje regionu, sielskie krajobrazy i wpaniała przyroda sprawiają, że nasz Penjonat jest idealnym miejscem na <span>wymarzony wypoczynek</span>.</p>
                  <p className={attractionStyles.mainText_invitation}><span>Serdecznie zapraszamy</span></p>
                </div>
              </div>
            </div>
        </Container>
      </section>
    </Layout>
  </>
);

export default AttractionPage
